.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




#demo-customized-select {
  height: 40px;
}
.search {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: flex-start;
}
.text-container {
  margin-right: 10px;
  display: flex;
  align-items: flex-end;
}
.date-picker-container {
  display: flex;
}
.MuiInput-formControl {
  border: 1px solid #ced4da;
}
.Mui-focused {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.MuiBox-root .MuiBox-root-34 {
  margin-bottom: 1rem;
}
#date-picker-inline {
  height: 40px;
  margin-right: 10px;
}
.search-container {
  display: flex;
  width: 90%;
}
.button-search {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.button-container {
  display: flex;
}
.MuiFormControl-marginNormal {
margin: 0;
}
.picker-container {
  display: flex;
}
.picker-container-element {
  width: 230px;
  margin-right: 10px;
}
.input-container {
  margin-top: 11px;
}
.field-notification {
  margin-bottom: 5px;
  font-size: 16px;
}
/*#link-more {
  background-color: #0d7bff;
  border-radius: 5px;
  color: white;
  width: 49%;
}*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}