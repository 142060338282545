body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media only screen and (min-width: 600px) {
  body {
    min-width: 1280px;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-family: "Corinthia", cursive;
}

a {
  color: inherit;
  text-decoration: none;
}

.notification-container {
  top: auto;
  bottom: 0;
}

.notification-container {
  width: auto
}

.notification-container span .notification {
  margin-bottom: 15px;
}

.flag-icon {
  height: .7em;
  background-size: cover !important;
  outline: 1px solid rgba(0, 0, 0, .2);
  margin-right: 1rem;
}
